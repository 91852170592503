
import CapexAccounts from "~/graphql/Portfolio/CapexAccounts.gql";
import TenancyFinancial from "~/graphql/Portfolio/TenancyFinancial.gql";

import ViewCapexes from "~/pages/portfolio/components/Expenses/ViewCapexes.vue";

import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";

import { Operator } from "~/helpers/apollo/apollo-utility-helpers";

export default {
  components: { ViewCapexes },

  inheritAttrs: false,

  props: {
    tenancyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    tenancy: {
      query: TenancyFinancial,
      variables() {
        return new InputBuilder()
          .setId(this.tenancyId)
          .setPagination(this.currentPage + 1, this.pageSize)
          .setSort("updated_at", "DESC")
          .setFilter({ matches: [{ category_type: [Operator.In, ["capex", "CAPEX"]] }] })
          .build();
      },
      update(data) {
        return data.assetManagementTenancy;
      },
    },

    capexCategories: {
      query: CapexAccounts,
      variables() {
        return new InputBuilder()
          .setFilter({ matches: [{ category_type: [Operator.In, ["capex", "CAPEX"]] }] }, "category")
          .setFilter({ matches: [{ property_id: [Operator.Equals, this.propertyId] }] }, "account")
          .build();
      },
      update(data) {
        return data.assetManagementCategories.items;
      },
      skip() {
        return this.propertyId == null;
      },
    },
  },

  data: () => ({
    currentPage: 0,
    pageSize: 20,
  }),

  computed: {
    loading() {
      return this.$apollo.queries.tenancy.loading || this.$apollo.queries.capexCategories.loading;
    },

    financialRecords() {
      if (this.tenancy?.assetManagementFinancialRecords?.items == null) {
        return [];
      }

      return this.tenancy.assetManagementFinancialRecords.items;
    },

    totalCount() {
      if (this.tenancy == null) {
        return 0;
      }

      return this.tenancy.assetManagementFinancialRecords.metadata.totalCount;
    },

    propertyId() {
      if (this.tenancy == null) {
        return null;
      }

      return this.tenancy.assetManagementProperty.id;
    },

    accounts() {
      if (this.capexCategories == null) {
        return [];
      }

      return this.capexCategories.flatMap((category) => category.assetManagementAccounts.items);
    },
  },

  methods: {
    pageChange(page) {
      this.currentPage = page;
    },

    update() {
      this.$apollo.queries.tenancy.refetch();
    },
  },
};
